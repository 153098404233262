@import '../../styles/variables.scss';
@import '../../styles/mixins.scss';

.container {
  @include resetButton();

  &.fit {
    display: contents;
  }

  &.disabled,
  &:disabled {
    pointer-events: none;
    cursor: unset;
    opacity: 0.5;
  }
}

.primary {
  color: var(--text-color);
  transition: color #{$theme-duration} ease-out;

  &.selected {
    font-weight: bold;
  }

  @include hover {
    &:not(.selected) {
      text-decoration: underline;
    }
  }
}

.secondary {
  color: var(--text-color);
  transition: color #{$theme-duration} ease-out;

  @include hover {
    text-decoration: none;
  }
}

.purchase {
  padding: 2px 6px;
  color: var(--text-color);
  border-right: 2px solid var(--text-color);
  border-bottom: 2px solid var(--text-color);
  transition: border-color #{$theme-duration} ease-out,
    color #{$theme-duration} ease-out;
}

.curate {
  opacity: inherit;
  margin-bottom: 10px;
  cursor: pointer;

  padding: 2px 6px;
  width: 100%;
  color: rgb(0, 92, 8); // this colour doesnt change
  background-color: rgb(239, 239, 239);
  border-right: 2px solid var(--text-color);
  border-bottom: 2px solid var(--text-color);
  transition: border-color #{$theme-duration} ease-out,
    color #{$theme-duration} ease-out;
}
